import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { AppStoreBtn } from '@/assets';

export default function AppStore() {
  return (
    <Link
      href="https://testflight.apple.com/join/mjUtPeNZ"
      target="_blank"
      className="button-shinny hover:after:rounded-lg"
    >
      <Image
        src={AppStoreBtn}
        alt="app_store_btn"
        className="h-12 w-auto md:h-14 lg:h-16"
      />
    </Link>
  );
}
